<template>
  <div class="main_app">
    <router-view />
  </div>

  <!-- Spinner, shown only when isLoading is true -->
  <div v-if="isLoading > 0" class="spinner-wrapper">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
import { provide } from "vue";
import { useMenuStore } from "./store/helper/posMenuStore"; // Make sure to create this file
import { isLoading } from "./store/LoadingState";

const menuStore = useMenuStore();

// Provide the menuStore to all child components
provide("menuStore", menuStore);

// menuStore.fetchMenuData();
</script>

<style>
@import url("./style/style.css");
</style>

<style scoped>
.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure spinner is above other content */
  background: rgba(255, 255, 255, 0.3); /* Light overlay behind spinner */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>

import Login from "../Views/Login/LogIn.vue";
import Home from "../Views/Home/HomeView.vue";
// import { watch } from "vue";

import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store/auth";

import { usePermissionStore } from "../store/helper/menuPaths";
import { useDRPermissionStore } from "../store/helper/DRmenuPaths";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  { path: "/login", name: "login", component: Login },
  {
    path: "/logout",
    name: "logout",
    meta: {
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      const authStore = useAuthStore();

      await authStore.logOut();

      next({ name: "login" });
    },
  },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: () => import("../Views/Login/ForgetPassword.vue"),
  },

  {
    path: "/reset-Password",
    component: () => import("../Views/UserProfile/ResetPassword.vue"),
  },
  {
    name: "Reset Forgot Password",
    path: "/resetForgotPassword/:username/:transactionId",
    component: () => import("../Views/Login/ResetForgotPassword.vue"),
  },
  {
    path: "/403",
    component: () => import("@/Views/error/error-403.vue"),
  },
  {
    path: "/404",
    component: () => import("@/Views/error/error-404.vue"),
  },

  {
    path: "/",
    name: "home",
    meta: { requiresAuth: true },
    redirect: { path: "/dashboard" },
    component: Home,
    children: [
      {
        path: "/MyProfile",
        component: () => import("../Views/UserProfile/MyProfile.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/tenant-Profile",
        component: () =>
          import("../Views/UserProfile/TenantProfile/TenantProfile.vue"),
      },
      {
        path: "/store-Profile",
        component: () =>
          import("../Views/UserProfile/StoreProfile/StoreProfile.vue"),
      },

      {
        path: "/dashboard",
        icon: "ri-dashboard-line  ri-lg",
        component: () => import("../components/Dashboard/IndexView.vue"),
        meta: { isPosMenu: false },
      },
      {
        path: "carts/allCarts",
        component: () => import("../Views/Cart/AllCarts.vue"),
        meta: { isPosMenu: true },
      },
      {
        name: "Cart Details",
        path: "carts/allCarts/cartDetails/:cartId",
        component: () => import("../Views/Cart/CartDetail.vue"),
      },
      {
        path: "orders/Dashboard",
        meta: { requiresAuth: true, isPosMenu: true },

        component: () => import("../Views/Orders/OrderDashboard.vue"),
      },
      {
        path: "orders/salesReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/SalesReport/SalesReport.vue"),
      },
      {
        name: "Order Details",

        path: "orders/salesReport/orderDetail/:orderId",
        component: () => import("../Views/Orders/SalesReport/OrderDetail.vue"),
      },
      {
        path: "orders/articleReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/ArticleReport.vue"),
      },
      {
        path: "orders/deliveryReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/DeliveryReport.vue"),
      },
      {
        path: "orders/discountReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/DiscountReport.vue"),
      },
      {
        path: "orders/mediaReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/MediaReport.vue"),
      },
      {
        path: "orders/paymentMediaReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/PaymentMediaReport.vue"),
      },
      {
        path: "orders/productSummaryReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/ProductSummaryReport.vue"),
      },
      {
        path: "orders/voidTransactionReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Orders/VoidTransactionReport.vue"),
      },
      {
        path: "returns/returnsReport",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Returns/ReturnReport/ReturnReport.vue"),
      },
      {
        name: "Returns Report Details",

        path: "returns/returnReport/returnDetail/:returnId",
        component: () =>
          import("../Views/Returns/ReturnReport/ReturnDetail.vue"),
      },
      {
        path: "returns/creditNotes",
        meta: { isPosMenu: true },

        component: () => import("../Views/Returns/CreditReport.vue"),
      },
      {
        path: "returns/returnArticleReport",
        meta: { isPosMenu: true },

        component: () => import("../Views/Returns/ReturnArticleReport.vue"),
      },
      {
        name: "Products",
        path: "products/products",
        meta: { isPosMenu: true },

        component: () => import("../Views/Product/Product/ProductView.vue"),
      },
      {
        name: "Product Details",

        path: "product/productDetail/:id",
        component: () =>
          import("../Views/Product/Product/ProductDetailModal.vue"),
      },
      {
        path: "products/catalogs",
        meta: { isPosMenu: true },

        component: () => import("../Views/Product/Catalogs/CatalogsView.vue"),
      },
      {
        path: "products/categories",
        meta: { isPosMenu: true },

        component: () => import("../Views/Product/CategoriesView.vue"),
      },
      {
        path: "products/pricebook",
        meta: { isPosMenu: true },

        component: () => import("../Views/Product/Pricebook/PricebookView.vue"),
      },
      {
        name: "Pricebook Entries",
        meta: { isPosMenu: true },

        path: "products/pricebookEntries",
        component: () => import("../Views/Product/PricebookEntries.vue"),
      },
      {
        path: "promotion/masterpromotions",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Product/MasterPromotion/MasterPromotions.vue"),
      },
      {
        path: "promotion/masterpromotions/createPromotion",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Product/MasterPromotion/CreatePromotion.vue"),
      },
      {
        path: "promotion/storePromotion",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Product/StorePromotions/StorePromotions.vue"),
      },

      {
        path: "inventory/InventoryList",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Inventory/Inventory/InventoryList.vue"),
      },
      {
        path: "inventory/stockrequest(inward)",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Inventory/StockRequest/StockRequest.vue"),
      },

      {
        name: "Edit Stock Request",

        path: "inventory/stockRequest/editStock/:id",
        component: () =>
          import("../Views/Inventory/StockRequest/EditStock.vue"),
      },
      {
        name: "Create Stock Request(Inward)",

        path: "inventory/stockrequest(inward)/createStockRequest",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Inventory/StockRequest/EditStock.vue"),
      },

      {
        path: "inventory/stockRequest/uploadModal/:id",
        component: () =>
          import("../Views/Inventory/StockRequest/UploadModal.vue"),
      },
      {
        name: "Stock Request Details",
        path: "inventory/stockRequest/stockRequestDetails/:id",
        component: () =>
          import("../Views/Inventory/StockRequest/DetailModal.vue"),
      },
      {
        name: "Stock Request GRN",
        path: "inventory/stockRequest/stockRequestGRNDetail/:id",
        component: () =>
          import("../Views/Inventory/StockRequest/StockRequestGRN.vue"),
      },

      {
        path: "inventory/stocktransfer(outward)",
        meta: { isPosMenu: true },

        component: () =>
          import(
            "../Views/Inventory/StockTransferOutward/StockTransferOutward.vue"
          ),
      },
      {
        meta: { isPosMenu: true },
        name: "Create Stock Transfer(Outward)",

        path: "inventory/stocktransfer(outward)/createStockTransfer",

        component: () =>
          import(
            "../Views/Inventory/StockTransferOutward/createStockTransfer.vue"
          ),
      },
      {
        name: "Edit Stock Transfer",
        path: "inventory/stockTransfer/editStock/:id",
        component: () =>
          import("../Views/Inventory/StockTransferOutward/EditStock.vue"),
      },
      {
        name: "Stock Transfer Details",
        path: "inventory/stockRequest/stockTransferDetails/:id",
        component: () =>
          import("../Views/Inventory/StockTransferOutward/DetailModal.vue"),
      },
      {
        name: "Stock Transfer GTN",
        path: "inventory/stockRequest/stockTransferGTNDetail/:id",
        component: () =>
          import(
            "../Views/Inventory/StockTransferOutward/StockTransferGTN.vue"
          ),
      },

      {
        path: "inventory/inwardHistory",
        meta: { isPosMenu: true },

        component: () => import("../Views/Inventory/InwardHistory.vue"),
      },
      {
        path: "inventory/inventoryHistory",
        meta: { isPosMenu: true },

        component: () => import("../Views/Inventory/InventoryHistory.vue"),
      },
      {
        label: "Customers",
        icon: "ri-user-6-line ri-lg",

        name: "All Customers List",
        meta: { isPosMenu: true },

        path: "customers/allCustomersList",
        component: () =>
          import("../Views/Customers/CustomersView/AllCustormersList.vue"),
      },
      {
        name: "Customer Details",
        path: "customers/customerDetail/:customerId",
        component: () =>
          import("../Views/Customers/CustomersView/CustomerDetailModal"),
      },
      {
        path: "manage/store",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/Store/StoreView.vue"),
      },
      {
        path: "manage/store/create-store",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/Store/Create-Store.vue"),
      },
      {
        path: "manage/user",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/User/UserView.vue"),
      },
      {
        path: "manage/user/create-User",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/User/Create-User.vue"),
      },
      {
        path: "manage/bankhandover",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Manage/BankHandover/BankHandover.vue"),
      },
      {
        path: "manage/bankhandover/createBankHandoverDeclaration",
        meta: { isPosMenu: true },

        component: () =>
          import(
            "../Views/Manage/BankHandover/createBankHandoverDeclaration.vue"
          ),
      },
      {
        path: "manage/Device",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/Device/DeviceView.vue"),
      },
      {
        name: "Payment Device Detail",
        path: "manage/paymentDevice/paymentDeviceDetail/:id",
        component: () => import("../Views/Manage/Device/DeviceDetail.vue"),
      },
      {
        path: "manage/multiCurrency",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Manage/MultiCurrency/Multi-Currency.vue"),
      },
      {
        path: "manage/dataJobs",
        meta: { isPosMenu: true },

        component: () => import("../Views/Manage/DataJobs.vue"),
      },
      {
        path: "content/campaignlist",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Content/CampaignList/campaignList.vue"),
      },
      {
        name: "Campaign Details",
        path: "content/campaignlist/campaignDetail/:id",
        component: () =>
          import("../Views/Content/CampaignList/campaignDetail.vue"),
      },
      {
        name: "Create Campaign",

        path: "content/campaignList/createCampaign",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Content/CampaignList/CreateCampaign.vue"),
      },
      // {
      //   name: "Edit Campaign",
      //   path: "content/campaignList/createCampaign/:id",
      //   component: () =>
      //     import("../Views/Content/CampaignList/EditCampaign.vue"),
      // },
      {
        path: "content/contentManagement",
        meta: { isPosMenu: true },

        component: () =>
          import("../Views/Content/ContentManagement/contentManagement.vue"),
      },
      {
        name: "Create Content Management",
        path: "content/contentManagement/createManagement",
        meta: { isPosMenu: true },

        component: () =>
          import(
            "../Views/Content/ContentManagement/CreatecontentManagement.vue"
          ),
      },
      // {
      //   name: "Edit Content",
      //   path: "content/contentManagement/EditcontentManagement/:id",
      //   component: () =>
      //     import(
      //       "../Views/Content/ContentManagement/EditcontentManagement.vue"
      //     ),
      // },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Routes for Digital Receipt

      {
        path: "/rdep-receipt",
        redirect: "/rdep-receipt/dashboard",
      },
      {
        path: "/rdep-receipt/dashboard",
        meta: { isDRMenu: false },
        component: () =>
          import("../Views/rdep-receipt/Dashboard/receiptDashboard.vue"),
      },
      {
        path: "/rdep-receipt/reports/digitalreceiptreport",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Report/DigitalReceiptReport.vue"),
      },
      {
        path: "/rdep-receipt/reports/transactionSummaryReport",
        meta: { isDRMenu: true },

        component: () =>
          import(
            "../Views/rdep-receipt/views/Report/TransactionSummary/TransactionSummary.vue"
          ),
      },
      {
        path: "/rdep-receipt/settings/invokeintegration",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/InvokeIntegration.vue"),
      },
      {
        path: "/rdep-receipt/settings/offers",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/Offer/OffersView.vue"),
      },
      {
        path: "/rdep-receipt/settings/socialchannel",
        meta: { isDRMenu: true },

        component: () =>
          import(
            "../Views/rdep-receipt/views/Settings/SocialChannel/SocialChannel.vue"
          ),
      },
      {
        path: "/rdep-receipt/settings/termandpolicy",
        meta: { isDRMenu: true },

        component: () =>
          import(
            "../Views/rdep-receipt/views/Settings/TermAndPolicy/TermAndPolicy.vue"
          ),
      },
      {
        path: "/rdep-receipt/settings/storeprofile",
        meta: { isDRMenu: true },

        component: () =>
          import(
            "../Views/rdep-receipt/views/Settings/StoreProfile/StoreProfile.vue"
          ),
      },
      {
        path: "/rdep-receipt/settings/certificate",
        meta: { isDRMenu: true },

        component: () =>
          import(
            "../Views/rdep-receipt/views/Settings/Certificate/CertificateView.vue"
          ),
      },
      {
        path: "/rdep-receipt/settings/store",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/Store/ReceiptStore.vue"),
      },
      {
        path: "/rdep-receipt/settings/store/createstore",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/Store/CreateStore.vue"),
      },
      {
        path: "/rdep-receipt/settings/users",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/User/ReceiptUser.vue"),
      },
      {
        path: "/rdep-receipt/settings/users/createUser",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/User/CreateUser.vue"),
      },
      {
        path: "/rdep-receipt/settings/co2_report",
        meta: { isDRMenu: true },

        component: () =>
          import("../Views/rdep-receipt/views/Settings/Co2Emission.vue"),
      },
      {
        path: "/rdep-receipt/manage/dataJobs",
        meta: { isDRMenu: true },

        component: () => import("../Views/Manage/DataJobs.vue"),
      },
    ],
  },
];

//This MenuItems is used to populate the main menu in navbar component
export const menuItems = [
  {
    path: "/dashboard",
    label: "Dashboard",

    icon: "ri-dashboard-line  ri-lg",
    // component: DashBoardVue,
    // component: () => {
    //   const user = useAuthStore.auth.currentUser;
    //   if (user.landingPage === "/" || user.landingPage === "") {
    //     console.log("dashboard");
    //     DashBoardVue;
    //   } else if (user.landingPage === "rdep-recepit") {
    //     ReceiptDasboard;
    //     console.log(" Receipt dashboard");
    //   }
    // },
  },
  {
    label: "Carts",
    icon: "ri-shopping-cart-line  ri-lg",

    children: [
      {
        label: "All Carts",
        path: "/carts/allCarts",
        // component: () => import("../components/Views/Cart/Cart.vue"),
      },
    ],
  },
  {
    name: "Orders",
    label: "Orders",
    icon: "ri-shopping-bag-line  ri-lg",

    children: [
      {
        label: "Dashboard",
        path: "/orders/orderDashboard",
      },
      {
        name: "Sales Report",
        label: "Sales Report",
        path: "/orders/salesReport",
      },
      {
        label: "Article Report",
        path: "/orders/articleReport",
      },
      {
        label: "Delivery Report",
        path: "/orders/deliveryReport",
      },
      {
        label: "Discount Report",
        path: "/orders/discountReport",
      },
      {
        label: "Media Report",
        path: "/orders/mediaReport",
      },
      {
        label: "Payment Media Report",
        path: "/orders/paymentMediaReport",
      },
      {
        label: "Product Summary Report",
        path: "/orders/productSummaryReport",
      },
      {
        label: "Void Transaction Report",
        path: "/orders/voidTransactionReport",
      },
    ],
  },
  {
    label: "Returns",
    icon: "ri-loop-left-line  ri-lg",
    children: [
      {
        label: "Returns Report",
        path: "/return/returnsReport",
      },
      {
        label: "Credit Notes",
        path: "/return/creditNote",
      },

      {
        label: "Return Article Report",
        path: "/return/returnArticleReport",
      },
    ],
  },
  {
    label: "Products",
    icon: "ri-briefcase-line  ri-lg",

    children: [
      {
        label: "Products",
        path: "/products/products",
      },
      {
        label: "Catalogs",
        path: "/products/catalogs",
      },
      {
        label: "Categories",
        path: "/products/categories",
      },
      {
        label: "Pricebook",
        path: "/products/pricebook",
      },
      {
        label: "Pricebook Entries",
        path: "/products/pricebookEntries",
      },
      {
        label: "Master Promotions",
        path: "/promotion/masterpromotions",
      },
    ],
  },
  {
    label: "Promotion",
    icon: "ri-discount-percent-line ri-lg",

    children: [
      {
        label: "Master Promotions",
        path: "/promotion/masterpromotions",
      },
      {
        label: "Store Promotions",
        path: "/promotion/storePromotion",
      },
    ],
  },
  {
    label: "Inventory",
    icon: "ri-building-3-line  ri-lg",

    children: [
      {
        label: "Inventory List",
        path: "/inventory/allInventoryList",
      },
      {
        label: "Stock Request(Inward) ",
        path: "/inventory/stockrequest(inward)",
      },
      {
        label: "Stock Transfer(Outward) ",
        path: "/inventory/stocktransfer(outward)",
      },
      {
        label: "Inward History ",
        path: "/inventory/inwardHistory",
      },
      {
        label: "Inventory History ",
        path: "/inventory/inventoryHistory",
      },
    ],
  },
  {
    label: "Customers",
    icon: "ri-user-6-line ri-lg",

    children: [
      {
        name: "All Customers List",
        label: "All Customers List",
        path: "/customers/allCustomersList",
      },
    ],
  },
  {
    label: "Manage",
    icon: "ri-settings-2-line ri-lg ",

    children: [
      {
        label: "Store",
        path: "/manage/store",
      },
      {
        label: "User",
        path: "/manage/user",
      },
      {
        label: "Bank Handover",
        path: "/manage/bankhandoverDeclaration",
      },
      {
        label: "Device",
        path: "/manage/paymentDevice",
      },
      {
        label: "Multi-Currency",
        path: "/manage/multiCurrency",
      },
      {
        label: "Data Jobs",
        path: "/manage/dataJobs",
      },
    ],
  },
  // {
  //   label: "Content",
  //   icon: "ri-user-6-line ri-lg",

  //   children: [
  //     {
  //       name: "Campaign List",
  //       label: "Campaign List",
  //       path: "/content/campaignlist",
  //     },
  //   ],
  // },

  // {
  //   label: "Report",
  //   icon: "ri-file-list-3-line ri-lg ",

  //   children: [],
  // },
  // {
  //   label: "Setting",
  //   icon: "ri-settings-2-line ri-lg ",

  //   children: [],
  // },
  // {
  //   label: "Manage",
  //   icon: "ri-tools-line ri-lg ",

  //   children: [],
  // },
];

export const readReceiptRoutes = [
  {
    label: "Dashboard",
    icon: "ri-home-4-line ri-lg ",
    path: "/rdep-receipt/dashboard",
  },
  {
    label: "Reports",
    icon: "ri-briefcase-line  ri-lg",

    children: [
      {
        label: "Digital Receipt Report",
        path: "/rdep-receipt/report/digitalreceiptreport",
      },
    ],
  },
  {
    label: "Settings",
    icon: "ri-settings-2-line ri-lg ",

    children: [
      {
        label: "Invoke Integration",
        path: "/rdep-receipt/settings/invokeintegration",
      },
      {
        label: "Offers",
        path: "/rdep-receipt/settings/offers",
      },
      {
        label: "Social channel",
        path: "/rdep-receipt/settings/socialchannel",
      },
      {
        label: "Term and policy",
        path: "/rdep-receipt/settings/termandpolicy",
      },
      {
        label: "Store profile",
        path: "/rdep-receipt/settings/storeprofile",
      },
      {
        label: "Certificate",
        path: "/rdep-receipt/settings/certificate",
      },
      {
        label: "Store",
        path: "/rdep-receipt/settings/store",
      },
      {
        label: "Users",
        path: "/rdep-receipt/settings/users",
      },
      {
        label: " CO₂  Emission Report",
        path: "/rdep-receipt/settings/co2_report",
      },
    ],
  },
  {
    label: "Manage",
    icon: "ri-briefcase-line  ri-lg",

    children: [
      {
        label: "Data Jobs",
        path: "/rdep-receipt/manage/dataJobs",
        // component: () => import("../components/Views/Cart/Cart.vue"),
      },
    ],
  },
];

// Create router
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

const publicPages = ["login", "forgotPassword", "Reset Forgot Password"];

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const permissionStore = usePermissionStore();
  const DRpermissionStore = useDRPermissionStore();

  // Check if the user is authenticated
  const isAuthenticated = authStore.isAuthenticated;

  // Define public pages that don't require authentication
  const isPublicPage = publicPages.includes(to.name);

  // Redirect unauthenticated users trying to access non-public pages to login
  if (!isAuthenticated && !isPublicPage) {
    return next({ name: "login" });
  }

  // Log out authenticated users trying to access the login page
  if (isAuthenticated && to.name === "login") {
    return next({ name: "home" });
  }

  if (isAuthenticated) {
    const landingPage = authStore.auth.landingPage;

    if (to.name === "home") {
      if (landingPage === "rdep-receipt" || landingPage === "/rdep-receipt") {
        return next({ path: "/rdep-receipt/dashboard" });
      } else if (landingPage === "/") {
        return next({ path: "/dashboard" });
      }
    }

    if (
      (landingPage === "rdep-receipt" || landingPage === "/rdep-receipt") &&
      to.path === "/dashboard"
    ) {
      return next({ path: "/rdep-receipt/dashboard" });
    }
  }

  // POS Menu permission check
  if (to.matched.some((record) => record.meta.isPosMenu)) {
    try {
      if (!permissionStore.isInitialized) {
        await permissionStore.initialize();
      }
      if (!permissionStore.isPathAllowed(to.path)) {
        console.warn(
          `Access restricted: ${to.path} is not in the allowed POS menu items`
        );
        return next("/404");
      }
    } catch (error) {
      console.error("Error initializing POS permission store:", error);
      return next("/404");
    }
  }

  // DR Menu permission check
  if (to.matched.some((record) => record.meta.isDRMenu)) {
    try {
      if (!DRpermissionStore.isInitialized) {
        await DRpermissionStore.initialize();
      }
      if (!DRpermissionStore.isDRPathAllowed(to.path)) {
        console.warn(
          `Access restricted: ${to.path} is not in the allowed DR menu items`
        );
        return next("/404");
      }
    } catch (error) {
      console.error("Error initializing DR permission store:", error);
      return next("/404");
    }
  }

  // If all checks pass, allow navigation to proceed
  next();
});

export default router;
